import useSWR from "swr";
import { EventsQuery, OrderByDirection } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import { sdk } from "~lib";
import { today } from "~lib/helpers/dates";

interface EventsServiceProps {
  search?: string;
  tourId?: string;
  isEnabled?: boolean;
  includeFeaturedEvents?: boolean;
}

export type EventsList = EventsQuery["events"]["edges"][number]["node"][];
interface EventsServiceReturn {
  error: Error;
  isLoading: boolean;
  events: EventsList;
  featuredEvents: EventsList;
}

const swrConfig = {
  shouldRetryOnError: false,
};

const getEvents = async (
  _: string,
  orgId: string,
  search: string,
  seasonId?: string
) => {
  const where = {
    startDate: today,
    ...(search && { q: search }),
    seasonId,
  };

  return sdk({ orgId })
    .events({
      where,
      orderBy: {
        startDate: OrderByDirection.Asc,
      },
    })
    .then((res) => res.events?.edges?.map(({ node }) => node));
};

const getFeaturedEvents = async (_: string, orgId: string) =>
  sdk({ orgId })
    .events({
      where: { isFeatured: true, startDate: today },
      orderBy: {
        startDate: OrderByDirection.Asc,
      },
    })
    .then((res) => res.events?.edges?.map(({ node }) => node));

export const useEventsService = ({
  search,
  tourId,
  isEnabled = true,
  includeFeaturedEvents = false,
}: EventsServiceProps = {}): EventsServiceReturn => {
  const { organization } = useOrganization();

  const { data: events, error: eventsError } = useSWR(
    isEnabled ? ["events", organization?.id, search, tourId] : null,
    getEvents,
    swrConfig
  );

  const { data: featuredEvents, error: featuredError } = useSWR(
    isEnabled && includeFeaturedEvents
      ? ["featured-events", organization?.id]
      : null,
    getFeaturedEvents,
    swrConfig
  );

  const error = (() => {
    if (!isEnabled) return;

    return includeFeaturedEvents ? eventsError || featuredError : eventsError;
  })();

  const isLoading = (() => {
    if (!isEnabled || error) return false;

    if (!events) return true;
    if (includeFeaturedEvents && !featuredEvents) return true;
  })();

  return {
    error,
    isLoading,
    events,
    featuredEvents,
  };
};
