import React, { useContext } from "react";

import {
  Box,
  Container,
  Divider,
  Flex,
  SearchInput,
  Text,
  Table,
} from "flicket-ui";
import {
  Footer,
  Icon,
  FeaturedEvents,
  ContentContainer,
  Status,
} from "~components";
import { BaseContext } from "~context";
import { EventFragment } from "~graphql/sdk";

import { Row } from "./overall.Row";

interface ListProps {
  isLoading: boolean;
  error: any;
  events: Partial<EventFragment>[];
  featuredEvents: Partial<EventFragment>[];
  setSearch: (search: string) => void;
}

export const List = ({
  isLoading,
  setSearch,
  events,
  error,
  featuredEvents,
}: ListProps) => {
  const organization = useContext(BaseContext);

  return (
    <ContentContainer variant="content">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth="1248px"
        height="220px"
        mx={"auto" as any}
        pl={4}
        d={{ _: "none !important", md: "block !important" }}
      />

      <Flex flexDir="column" flex="1">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="1248px"
          mx={"auto" as any}
          pl={4}
          d={{ _: "none !important", md: "block !important" }}
        />
        <Container>
          {!!featuredEvents?.length && (
            <>
              {organization.isSinglePage ? (
                <Text
                  fontSize={4}
                  lineHeight="normal"
                  fontWeight="heavy"
                  color="N800"
                  d={{ _: "block", md: "none" }}
                >
                  Featured tickets
                </Text>
              ) : (
                <Text
                  fontSize={3}
                  lineHeight="normal"
                  fontWeight="heavy"
                  color="N800"
                  d={{ _: "block", md: "none" }}
                >
                  Featured events
                </Text>
              )}
              <Divider
                mt={1}
                mb="6/4"
                d={{ _: "block", md: "none !important" }}
              />
              <FeaturedEvents events={featuredEvents as any[]} />
            </>
          )}
          <Flex
            justifyContent="space-between"
            alignItems="right"
            width="100%"
            mb={{ _: "6/4", sm: 2, md: 3 }}
            mt={!!featuredEvents?.length && { _: 3, md: 0 }}
          >
            <Text
              fontSize={{ _: 5, sm: 6, md: 7 }}
              lineHeight={{ _: "normal", md: "medium" }}
              fontWeight={{ _: "heavy", md: "extraBold" }}
              color="N800"
            ></Text>
            <Box width="357px" d={{ _: "none", md: "flex" }}>
              <SearchInput
                onChange={setSearch}
                searchIcon={<Icon icon="search" color="N800" fontSize={6} />}
              />
            </Box>
          </Flex>
          <Divider />
          <Box mt={{ _: 0, md: 4 }}>
            <Status
              noResultText="No upcoming events, check back soon!"
              loading={isLoading}
              error={error}
            >
              <Table data={events} row={(props) => <Row {...props} />} />
            </Status>
          </Box>
        </Container>
      </Flex>
      <Footer />
    </ContentContainer>
  );
};
export default List;
